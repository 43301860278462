import React, { useState, useEffect } from 'react';
import mapOptions from './mapOptions'
import './App.css';
import GoogleMapReact from 'google-map-react';


const coords = {
	lat: 4.1747709,
	lng: 73.5061215
};

const params = {
	v: '3.exp',
	key: 'AIzaSyAtIKM1INBVRJWqRy0Puc9YG5BWfMDp0WA',
};




function getData() {
	return fetch('/data.json').then(d => d.json())
}



function App() {

	const [islands, setIslands] = useState([]);
	const [selectedIsland, setSelectedIsland] = useState(null);
	const [center] = useState(coords);
	const [map, setMap] = useState(false);
	const [zoom, setZoom] = useState(7);
	// const [max, setMax] = useState(0);
	// const [loading, setLoading] = useState(false);

	// let mapRef = React.createRef();

	const handleApiLoaded = (map, maps) => {

		setMap(map)

		islands.map(island => {


			console.log(island);

			let circle = new window.google.maps.Circle({
				strokeColor: island.color || '#FF0000',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: island.color || '#FF0000',
				fillOpacity: 0.3,
				map,
				center: {"lat": island.Latitude ,"lng":island.Longitude },
				radius: island.Active,
				zIndex: 2
			})



			circle.addListener("click", function main(){
				console.log('lciked....');
				setSelectedIsland(island)
			});

			if(island.IslandName === 'Male\'') {
				console.log(circle);
			}


		})

		fetch('/map.json').then(d => d.json()).then(d => {
			map.data.addGeoJson(d)
			map.data.setStyle(function (feature) {
				return {
					fillColor: '#29446b',
					strokeColor: '#375079',
					strokeWeight: 1,
					zIndex: 1
				}
			});
		})

		
	};

	useEffect(() => {
		// setLoading(true)
		getData().then(d => {
			setIslands(d)
			// setMax(d.sort((a,b) => a.AllCases > b.AllCases? 1:-1)[d.length-1].AllCases)
			console.log(islands, d);
		})
	}, [])

	// const mapEvents = {
	// 	onMapCreated(map) {
	// 		map.setOptions({
	// 			disableDefaultUI: true
	// 		});
	// 	},
	// 	onClick(e) {
	// 		console.log('onClick', e);
	// 		setSelectedIsland(e)
	// 	}
	// }
	// const data = {
	// 	labels: [
	// 		'Red',
	// 		'Green',
	// 		'Yellow'
	// 	],
	// 	datasets: [{
	// 		data: [300, 50, 100],
	// 		backgroundColor: [
	// 			'#FF6384',
	// 			'#36A2EB',
	// 			'#FFCE56'
	// 		],
	// 		hoverBackgroundColor: [
	// 			'#FF6384',
	// 			'#36A2EB',
	// 			'#FFCE56'
	// 		]
	// 	}]
	// };

	const zoomLevel = {
		6: {min: 5000, max: 10000},
		7: {min: 50, max: 4999},
		8: {min: 5, max: 1999},
		9: {min: 1, max: 999},
		10: {min: 1, max: 999},
		11: {min: 1},
		12: {min: 1},
		13: {min: 1},
		15: {min: 1},
	}

	const isWorthDisplaying = (c) => {
		let level = zoomLevel[zoom]
		if(level) {
			return level.min < c;
		}
		
		return false;
	}

	const min = (number) => {
		let n = number;
		return n 
	}


	// const Circle = ({ island }) =>  isWorthDisplaying(island.AllCases) && <div className='circle' style={{ width: min(island.Active) + 'px', height: min(island.Active) + 'px', lineHeight: min(island.Active) + 'px', backgroundColor: island.color || (island.Active > 5 ? `rgba(183, 28, 28, ${Math.round(((island.AllCases - island.Active) / island.AllCases) * 100, 2) / 100} )` : `rgba(208, 128, 0, ${Math.round((island.Active / island.AllCases) * 100, 2) / 100})`) }} onClick={() => setSelectedIsland(island)}  >
	// 	<div style={{ animationDelay: `${Math.random() * 2}s` }} className={island.Active > 5 ? 'pulse' : ''}></div>
	// 		<div className='circle-body'></div>
	// </div> ;

	const Circle = ({island, center}) => new window.google.maps.Circle({
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: '#FF0000',
		fillOpacity: 0.3,
		map,
		center: {center},
		radius: 275,
	  })




	const InfoWindow = ({ content }) => <div>{content}</div>

	const preview = () => (
		<div className='info-window'>
			<div className='heading'>{selectedIsland.IslandName} <div onClick={() => setSelectedIsland(null)} className='close'>‎×</div> </div>
			<div className='body'>
				<div className='percentage'>
					<div className='bar' style={{ width: Math.round((selectedIsland.Active / selectedIsland.AllCases) * 100, 2) + '%' }}></div>
					{/* {selectedIsland.Active}% */}
				</div>
				<div className='flex'>
					<div className='flex-cell'>
						<h4>Active</h4>
						<span>
							{selectedIsland.Active}
						</span>
					</div>
					<div className='flex-cell'>
						<h4>All Cases</h4>
						<span>
							{selectedIsland.AllCases}
						</span>
					</div>
				</div>
			</div>
		</div>
	)


	let islandComponents = map && islands.filter(i => i.Active > 0).map(center => (
		<Circle
			key={center.AtollName + center.IslandName}
			lat={center.Latitude}
			lng={center.Longitude}
			center={{lat: center.Latitude, lng: center.Longtitude }}
			island={center}
		/>
	))


	return (
		<div className="App">

			<GoogleMapReact
				bootstrapURLKeys={{ key: params.key }}
				defaultCenter={[center.lat, center.lng]}
				defaultZoom={zoom}
				yesIWantToUseGoogleMapApiInternals
				onZoomAnimationStart={setZoom}
				options={mapOptions}
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
			>
				{/* {islandComponents} */}
				{
					selectedIsland &&
					<InfoWindow
						lat={selectedIsland.Latitude}
						lng={selectedIsland.Longitude}
						content={preview()}
						onCloseClick={() => setSelectedIsland(null)}
					/>

				} 


			</GoogleMapReact>
		</div>
	);
}

export default App;
